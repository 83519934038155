import React from 'react';
import '../../static/styles/main.scss';

const SuccessPage = () => (
  <div className='success-hero'>
    <div>
      <h1>Congrats!</h1>
      <p>
        We are so excited to help you reach your goals next year. Keep an eye
        out for your invoice to purchase the package as well as a call from us
        to get you scheduled for your first visit starting January 3!
      </p>
      <a className='btn' href='https://peakrxtherapy.com/'>
        Visit Website
      </a>
    </div>
  </div>
);

export default SuccessPage;
